import React from "react";


export const ShareButtons = ({ couponCode }) => {
    const x_url = encodeURIComponent("https://ud.laundry-pack.com/lp/?utm_source=x&utm_medium=social&utm_campaign=share_button&syokai_code=" + couponCode);
    const line_url = encodeURIComponent("https://ud.laundry-pack.com/lp/?utm_source=line&utm_medium=social&utm_campaign=share_button&syokai_code=" + couponCode);
    const facebook_url = encodeURIComponent("https://ud.laundry-pack.com/lp/?utm_source=facebook&utm_medium=social&utm_campaign=share_button&syokai_code=" + couponCode);
    const x_text = encodeURIComponent("おうちにいながら、お手軽クリーニング！\nUber Eats 配達パートナーが\n✅集荷\n✅お届け\nまでしてくれる\n忙しいあなたにピッタリ！\n\n宅配クリーニング【LAUNDRY PACK Now】\n\n初回限定キャンペーン実施中✨\n登録はこちら！\n#宅配クリーニング #UberEats #ランドリーパック\n");
    const line_text = encodeURIComponent("おうちにいながら、お手軽クリーニング！\n#Uber Eats 配達パートナーが\n✅集荷\n✅お届け\nまでしてくれる\n忙しいあなたにピッタリ！\n\n宅配クリーニング【LAUNDRY PACK Now】\n\n初回限定キャンペーン実施中✨\n登録はこちら！\n");

    return (
        <div className="share-buttons">
            {/* X（旧Twitter） */}
            <a href={`https://twitter.com/intent/tweet?url=${x_url}&text=${x_text}`}
               target="_blank" rel="noopener noreferrer">
                <img src="https://cdn.simpleicons.org/x/000000" alt="Xでシェア" width="40" />
            </a>

            {/* LINE */}
            <a href={`https://line.me/R/msg/text/?${line_text}%0A${line_url}`}
               target="_blank" rel="noopener noreferrer" className="ml-4">
                <img src="https://cdn.simpleicons.org/line/06C755" alt="LINEでシェア" width="40" />
            </a>

            {/* Facebook */}
            <a href={`https://www.facebook.com/sharer/sharer.php?u=${facebook_url}`}
               target="_blank" rel="noopener noreferrer" className="ml-4">
                <img src="https://cdn.simpleicons.org/facebook/1877F2" alt="Facebookでシェア" width="40" />
            </a>
        </div>
    );
};

