import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BsChevronLeft } from "react-icons/bs";
import { Tabbar } from "../compornents/Tabbar";
import { Post } from '../compornents/Post';
import { Analytics } from '../compornents/Analytics';
import { FaRegCopy } from "react-icons/fa";
import { toast } from 'react-toastify';
import { ShareButtons } from "../compornents/ShareButtons";

export const Syokai = () => {
  const navigate = useNavigate();
  const [customer, setCustomer] = useState([]);
  // クーポンコードをコピー
  const copyCouponCode = () => {
    navigator.clipboard.writeText(customer
      .coupon_code);
    toast('クーポンコードをコピーしました');
  };
  useEffect(() => {
    // 顧客情報取得
    async function getCustomer() {
      const request_data = {}
      // post
      const response_data = await Post('post', '/customer/index.php', request_data);
      // 結果
      // console.log(response_data);
      if (response_data.result === 1) {
        const customer = response_data.customer;
        setCustomer(customer);
      } else {
        // エラー

      }
    }
    getCustomer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // 戻る
  const back = () => {
    navigate(-1);
  };
  return (
    <div className="App">
      <div className="Address">
        <Analytics title="お友達紹介" />
        <div className="toolbar mb-3">
          <div className="toolbar_left div_btn" onClick={back} >
            <BsChevronLeft />
          </div>
          <div className="toolbar_center">
            お友達紹介
          </div>
          <div className="toolbar_right">
          </div>
        </div>
        <div className="m-4">
          <h3 className="mb-4">
            お友達紹介クーポン
          </h3>
          <div className="mb-4">
            <div className="mb-2">
              お友達があなたのクーポンコードを入力すると、<span className="h6 text-primary">500円引きクーポンをプレゼント！</span><br />
              さらに、友達の登録完了後、あなたにも<span className="h6 text-primary">200円引きクーポンをプレゼント！</span><br />
              ぜひ、お友達にシェアして、お得にご利用ください！
            </div>
          </div>
          <hr/>
          <div className="h5 text-center mt-3 mb-3">SNSでシェアしよう！</div>
          <div className="mb-2 text-center">
            <ShareButtons couponCode={customer.coupon_code}/>
          </div>
          <hr/>
          <div className="h5 text-center mt-3 mb-3">あなたのクーポンコードを<br/>シェアしよう！</div>
          <div className="mb-3 text-center">
            <button className="btn btn-yellow w-100 p-2" onClick={() => copyCouponCode()}>
                <div className="text-sm">あなたのクーポンコード</div>
                <div className="h5">{customer.coupon_code}<span className="ml-2"><FaRegCopy /></span></div>
            </button>
          </div>
          <hr/>
          <div className="h5 text-center mt-3 mb-3">注意事項</div>
          <div className="text-sm text-muted px-3">
            <ul className="text-left pl-1">
              <li>クーポンは1回のご注文につき1枚のみ使用できます。</li>
              <li>紹介クーポンは、新規会員登録時にのみご入力いただけます。</li>
              <li>付与されたクーポンは発行日から1年間有効です。</li>
              <li>本キャンペーンは予告なく変更・終了する場合があります。</li>
              <li>新規入会特典などのその他の割引やキャンペーンとの併用はできません。</li>
            </ul>
          </div>
        </div>
        <Tabbar menu="account" />
      </div>
    </div>
  );



}
