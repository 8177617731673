import axios from 'axios';
import qs from 'qs';

export async function Post(action, uri, request_data) {

    // POST
    let error = [];
    let response = [];
    let is_success = false;
    // console.log(JSON.stringify(request_data));
    // pのGETがあればそれも送る
    const query = new URLSearchParams(window.location.search);
    const p = query.get('p');
    // console.log(p);
    if (p) {
        // console.log('ok');
        action = 'login';
        request_data['p'] = p;
    }
    try{
        var domain = '';
        if (process.env.REACT_APP_IS_DEV !== 'true') {
            domain = "https://ud.laundry-pack.com/proxy/index.php";
        }else{
            domain = "https://ud3.laundry-pack.com/proxy/index.php";
        }
        await axios({
            withCredentials: true, // ←これを追加
            method: "POST",
            url: domain ,
            data: qs.stringify({
                'action': action,
                'uri': uri,
                'p': p,
                'request_data': JSON.stringify(request_data),
            }),
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        })
        .then((res) => {
            // console.log(res);
            response = res;
            is_success = true;
            // secエラーならリダイレクト
            if (response.data.result === 0 && response.data.error_code === 'E999') {
                window.location.href = '/login';
            }
        })
        .catch((e) => {
            // console.log(e);
            error['result'] = 0;
            error['error_code'] = 'E000';
            error['error_message'] = '通信エラーです。しばらくして再度お試しください。';
        });
    } catch (error) {
        error['result'] = 0;
        error['error_code'] = 'E000';
        error['error_message'] = '通信エラーです。しばらくして再度お試しください。';
    }
    if (is_success) {
        return response.data;
    } else {
        return error;
    }
}

export async function PostFile(action, uri, request_data,file) {

    // POST
    let error = [];
    let response = [];
    let is_success = false;
    try {
        var domain = '';
        if (process.env.REACT_APP_IS_DEV !== 'true') {
            domain = "https://ud.laundry-pack.com/proxy/image.php";
        } else {
            domain = "https://ud3.laundry-pack.com/proxy/image.php";
        }
        const formData = new FormData();
        formData.append('img', file);
        formData.append('action', 'post');
        formData.append('uri', '/order/create_comment_image.php');
        formData.append('request_data', JSON.stringify(request_data));
        await axios.post(domain, formData)
            .then((res) => {
                // console.log(res);
                response = res;
                is_success = true;
                // secエラーならリダイレクト
                if (response.data.result === 0 && response.data.error_code === 'E999') {
                    window.location.href = '/login';
                }
            })
            .catch((e) => {
                // console.log(e);
                error['result'] = 0;
                error['error_code'] = 'E000';
                error['error_message'] = '通信エラーです。しばらくして再度お試しください。';
            });
    } catch (error) {
        error['result'] = 0;
        error['error_code'] = 'E000';
        error['error_message'] = '通信エラーです。しばらくして再度お試しください。';
    }
    if (is_success) {
        return response.data;
    } else {
        return error;
    }
}
