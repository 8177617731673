/* eslint-disable no-unused-vars */
import { useState, useEffect, useRef } from "react";
import { Client as Styletron } from "styletron-engine-monolithic";
import { Provider as StyletronProvider } from "styletron-react";
import { LightTheme, BaseProvider } from "baseui";
import { useNavigate, Link} from 'react-router-dom';
import { Post } from '../../compornents/Post';
import { toast } from 'react-toastify';
import { useModal } from 'react-hooks-use-modal';
import { TailSpin } from 'react-loading-icons'
import { Analytics, Gtag } from '../../compornents/Analytics';

export const Register = () => {
  const navigate = useNavigate();
  const [tel, setTel] = useState("");
  const telInputRef = useRef(null);
  const engine = new Styletron();

  // モーダル
  const [Modal, openModal, closeModal] = useModal('root', {
    preventScroll: true //これはオプション。デフォルトはfalse
  });

  async function send_sms() {
    openModal();
    const request_data = {
      'key': 'yt~z7ESU6J~tLB7ppw(CLD8WddX9PNsr6DH',
      'tel': tel,
      'is_register': 1,
    }
    // post
    const response_data = await Post('public', '/auth/send_sms.php', request_data);
    if (response_data.result === 1) {
      navigate("/register/auth", { state: { customerId: response_data.customer_id,tel:tel } });
      // SMS送信成功
      Gtag('send_sms_success');
    } else {
      // SMS送信失敗
      Gtag('send_sms_failed', {
        error_message: response_data.error_message,
      });
      // エラー
      toast(response_data.error_message);
    }
    closeModal();
  }

  useEffect(() => {
    Gtag('view_register_send_sms');
    if (telInputRef.current) {
      telInputRef.current.focus(); // ← 2. マウント時にフォーカス
    }
  }, []);

  return (
    <StyletronProvider value={engine}>
      <BaseProvider theme={{ ...LightTheme, direction: "rtl" }}>
        <Analytics title="携帯電話番号入力" />
        <div className="Register m-auto">
          <div className="App-tel">
            <div className="tel-title">{"新規登録(SMS認証)"}</div>
            <div className="App-comment">{"携帯電話番号を入力してください"}</div>
            <div className="App-telno">
              <input ref={telInputRef} type="tel" value={tel} onChange={e => setTel(e.target.value)} placeholder="例）09012345678" className="form-control" />
            </div>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '20px',
            }}>
              {tel.length === 11 ? <button className="button-next" onClick={send_sms}>{"次へ"}</button> : <button className="button-next-g">{"次へ"}</button>}
            </div>
            <div className="text-center mt-3">
              <Link to="/login" className="text-black">ログインはこちら</Link>
            </div>
          </div>
        </div>
        <Modal>
          <div className="modal rounded text-center pb-4 pl-4 pr-4">
            <button onClick={closeModal} className="hide">閉じる</button>
            <div >
              <TailSpin stroke="#000" />
            </div>
            <div className="">
              <div className="">
                処理中です
              </div>
            </div>
          </div>
        </Modal>
      </BaseProvider>
    </StyletronProvider>
  );
}
