import { Routes, Route, BrowserRouter } from "react-router-dom";
import { Top } from "./screens/Top";
import { RegisterStep1 } from "./screens/register/RegisterStep1";
import { RegisterStep2 } from "./screens/register/RegisterStep2";
import { RegisterMenu } from "./screens/register/RegisterMenu";
import { RegisterPostcode } from "./screens/register/RegisterPostcode";
import { Register } from "./screens/register/Register";
import { RegisterAuth } from "./screens/register/RegisterAuth";
import { RegisterProfile } from "./screens/register/RegisterProfile";
import { OrderStep0 } from "./screens/order_step/OrderStep0";
import { OrderStep1 } from "./screens/order_step/OrderStep1";
import { OrderStep2 } from "./screens/order_step/OrderStep2";
import { OrderStep3 } from "./screens/order_step/OrderStep3";
import { OrderStep4 } from "./screens/order_step/OrderStep4";
import { OrderStep5 } from "./screens/order_step/OrderStep5";
import { Prepare } from "./screens/Prepare";
import Home from "./screens/Home";
import { Option } from "./screens/Option";
import { Confirm } from "./screens/Confirm";
import { Address } from "./screens/Address";
import { Location } from "./screens/Location";
import { Location2 } from "./screens/Location2";
import { Notfound } from "./screens/Notfound";
import { Orders } from "./screens/Orders";
import { Order } from "./screens/Order";
import { Account } from "./screens/Account";
import { Profile } from "./screens/Profile";
import { Cleaning } from "./screens/Cleaning";
import { Other } from "./screens/Other";
import { Privacy } from "./screens/Privacy";
import { Rule } from "./screens/Rule";
import { Tokutei } from "./screens/Tokutei";
import { Company } from "./screens/Company";
import { Baisyo } from "./screens/Baisyo";
import { Leave } from "./screens/Leave";
import { Credit } from "./screens/Credit";
import { RealTimeMap } from "./screens/RealTimeMap";
import { Coupon } from "./screens/Coupon";
import { Syokai } from "./screens/Syokai";
import "./css/App.css";
import './css/Bootstrap.css';
import { ToastContainer } from 'react-toastify';
import { Login } from "./screens/Login";
import { LoginAuth } from "./screens/LoginAuth";
import { Help } from "./screens/help/Help";
import { Help1 } from "./screens/help/Help1";
import { Help2 } from "./screens/help/Help2";
import { Notifications } from "./screens/Notifications";
import { Notification } from "./screens/Notification";

function App() {
  return (
    <div className="LaundryPackApp">
    < ToastContainer
      position="top-center"
      autoClose={3000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover={false}
      theme="dark"
    />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Top />} />
          <Route path="/register/step1" element={<RegisterStep1 />} />
          <Route path="/register/step2" element={<RegisterStep2 />} />
          <Route path="/register/menu" element={<RegisterMenu />} />
          <Route path="/register/postcode" element={<RegisterPostcode />} />
          <Route path="/register" element={<Register />} />
          <Route path="/register/auth" element={<RegisterAuth />} />
          <Route path="/register/profile" element={<RegisterProfile />} />
          <Route path="/order_step/step0" element={<OrderStep0 />} />
          <Route path="/order_step/step1" element={<OrderStep1 />} />
          <Route path="/order_step/step2" element={<OrderStep2 />} />
          <Route path="/order_step/step3" element={<OrderStep3 />} />
          <Route path="/order_step/step4" element={<OrderStep4 />} />
          <Route path="/order_step/step5" element={<OrderStep5 />} />
          <Route path="/prepare" element={<Prepare />} />
          <Route path="/home" element={ <Home /> } />
          <Route path="/option" element={ <Option /> } />
          <Route path="/confirm" element={<Confirm />} />
          <Route path="/address" element={<Address />} />
          <Route path="/location" element={<Location />} />
          <Route path="/location2" element={<Location2 />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/order/:order_id" element={<Order />} />
          <Route path="/account" element={<Account />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/cleaning" element={<Cleaning />} />
          <Route path="/other" element={<Other />} />
          <Route path="/company" element={<Company />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/rule" element={<Rule />} />
          <Route path="/tokutei" element={<Tokutei />} />
          <Route path="/baisyo" element={<Baisyo />} />
          <Route path="/leave" element={<Leave />} />
          <Route path="/credit" element={<Credit />} />
          <Route path="/real_time_map" element={<RealTimeMap />} />
          <Route path="/coupon" element={<Coupon />} />
          <Route path="/syokai" element={<Syokai />} />
          <Route path="/login" element={<Login />} />
          <Route path="/login/auth" element={<LoginAuth />} />
          <Route path="/help" element={<Help />} />
          <Route path="/help1" element={<Help1 />} />
          <Route path="/help2" element={<Help2 />} />
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/notification/:notification_id" element={<Notification />} />
          <Route path="*" element={ <Notfound /> } />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
