import { useNavigate } from "react-router-dom";
import { BsChevronLeft } from "react-icons/bs";
import { Tabbar } from "../../compornents/Tabbar";
import { Analytics } from '../../compornents/Analytics';

export const Help2 = () => {
  const navigate = useNavigate();
  const back = () => {
    navigate(-1);
  };
  return (
    <div className="App">
      <div className="Address">
        <Analytics title="ヘルプ" />
        <div className="toolbar mb-3">
          <div className="toolbar_left div_btn" onClick={back} >
            <BsChevronLeft />
          </div>
          <div className="toolbar_center">
            ご利用の流れ
          </div>
          <div className="toolbar_right">
          </div>
        </div>
        <div className="m-4">
          <div className="mb-5">
            <div className="h5 mb-3">専用バッグを受け取る(初回のみ)</div>
            <div className="mb-3">
              <div className="mb-2">
                1.サイトにログイン後<strong>【初回限定】無料専用バッグ</strong>を選択してください。
              </div>
              <div>
                <img src="./img/help/help1.png" alt="help1" className="ItemImage" />
              </div>
            </div>
            <div className="mb-3">
              <div className="mb-2">
                2.注文内容の確認し、注文してください。
              </div>
              <div>
                <img src="./img/help/help2.png" alt="help2" className="ItemImage" />
              </div>
            </div>
            <div className="mb-3">
              <div className="mb-2">
                3.Uber配達員の到着をお待ちください。
              </div>
              <div>
                <img src="./img/help/help3.png" alt="help3" className="ItemImage" />
              </div>
            </div>
            <div className="mb-3">
              <div className="mb-2">
                4.Uber配達員に暗証番号をお伝えし、専用バッグを受け取ったら完了です。
              </div>
              <div>
                <img src="./img/uketori.png" alt="uketori" className="ItemImage" />
              </div>
            </div>
          </div>
          <hr />
          <div className="mb-5">
            <div className="h5 mb-3">クリーニングを注文</div>
            <div className="mb-3">
              <div className="mb-2">
                1.専用バッグに衣類を入れて、しっかりと密封してください。
                <div className="text-danger">
                  ※ご依頼品を専用バッグに入れていない場合、もしくは密封されていない場合、サービスをお受けできない場合があります。
                </div>
              </div>
              <div>
                <img src="/img/topb.png" alt="bag" className="ItemImage" />
              </div>
            </div>
            <div className="mb-3">
              <div className="mb-2">
                2.メニューを選択してください。
              </div>
              <div>
                <img src="./img/help/help4.png" alt="help4" className="ItemImage" />
              </div>
            </div>
            <div className="mb-3">
              <div className="mb-2">
                3.注文内容の確認し、注文してください。
              </div>
              <div>
                <img src="./img/help/help5.png" alt="help5" className="ItemImage" />
              </div>
            </div>
            <div className="mb-3">
              <div className="mb-2">
                4.Uber配達員に専用バッグを渡したら完了です。
              </div>
              <div>
                <img src="./img/iruiwowatasu.png" alt="uketori" className="ItemImage" />
              </div>
            </div>
          </div>
          <hr />
          <div className="mb-5">
            <div className="h5 mb-3">クリーニング品を受け取る</div>
            <div className="mb-3">
              <div className="mb-2">
                1.SMSでクリーニング完了通知が届きます。
              </div>
              <div>
                <img src="/img/cleaningkanryo.png" alt="bag" className="ItemImage" />
              </div>
            </div>
            <div className="mb-3">
              <div className="mb-2">
                2.サイトにログイン後、今すぐ受け取るボタンを押してください。
              </div>
              <div>
                <img src="./img/help/help6.png" alt="help6" className="ItemImage" />
              </div>
            </div>
            <div className="mb-3">
              <div className="mb-2">
                3.Uber配達員に暗証番号をお伝えし、クリーニング品を受け取ったら完了です。
              </div>
              <div>
                <img src="./img/uketori.png" alt="help6" className="ItemImage" />
              </div>
            </div>
          </div>
        </div>
        <Tabbar menu="account" />
      </div>
    </div>
  );



}
