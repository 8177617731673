/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { Client as Styletron } from "styletron-engine-monolithic";
import { Provider as StyletronProvider } from "styletron-react";
import { LightTheme, BaseProvider } from "baseui";
import { Input } from "baseui/input";
import { Textarea } from "baseui/textarea";
import { PinCode } from "baseui/pin-code";
// import {useStyletron} from 'baseui';
// import { FormControl } from "baseui/form-control";
import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps';
import { useNavigate, useLocation } from 'react-router-dom';
// import axios from 'axios';
import { Post } from '../compornents/Post';
import { toast } from 'react-toastify';
import { useModal } from 'react-hooks-use-modal';
import { TailSpin } from 'react-loading-icons'
import { Analytics, Gtag } from '../compornents/Analytics';

export const LoginAuth = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const engine = new Styletron();
  const [values, setValues] = useState(["", "", "", "", "", ""]);
  const [smsError, setSmsError] = useState("");
  const customerId = location.state.customerId;
  const tel = location.state.tel;

  // モーダル
  const [Modal, openModal, closeModal] = useModal('root', {
    preventScroll: true //これはオプション。デフォルトはfalse
  });

  async function check_sms() {
    openModal();
    const request_data = {
      'key': 'yt~z7ESU6J~tLB7ppw(CLD8WddX9PNsr6DH',
      'customer_id': customerId,
      'sms_auth_code': values[0] + values[1] + values[2] + values[3] + values[4] + values[5],
    }
    // post
    const response_data = await Post('login', '/auth/check_sms.php', request_data);
    // console.log(response_data);
    if (response_data.result === 1) {
      // SMS認証成功
      Gtag('login_authcode_success');
      if (response_data.is_new === 0) {
        navigate('/home');
      }else{
        navigate('/register/');
      }
    } else {
      // SMS認証失敗
      Gtag('login_authcode_failed', {
        error_message: response_data.error_message,
      });
      setSmsError("error");
    }
    closeModal();
  }



  useEffect(() => {
    Gtag('view_login_auth');
  }, []);

  return (
    <StyletronProvider value={engine}>
      <BaseProvider theme={{ ...LightTheme, direction: "rtl" }}>
        <Analytics title="認証コード入力" />
        <div className="Register m-auto">
          <div className="App-tel">
            <div className="tel-title">{"届いた認証コードを\n入力してください"}</div>
            <div className="App-comment">{tel}{"に届いた6桁の\n認証コードを入力してください"}</div>
            <div className="App-telno">
              <PinCode
                values={values}
                onChange={({ values }) => setValues(values)}
                autoFocus
                error={smsError}
              />
            </div>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '20px',
            }}>
              {!values.includes('') ? <button className="button-next" onClick={check_sms}>{"認証する"}</button> : <button className="button-next-g">{"認証する"}</button>}
            </div>
          </div>
        </div>

        <Modal>
          <div className="modal rounded text-center pb-4 pl-4 pr-4">
            <button onClick={closeModal} className="hide">閉じる</button>
            <div >
              <TailSpin stroke="#000" />
            </div>
            <div className="">
              <div className="">
                処理中です
              </div>
            </div>
          </div>
        </Modal>
      </BaseProvider>
    </StyletronProvider>
  );
}
