import { IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { BsChevronLeft } from "react-icons/bs";
import { Tabbar } from "../../compornents/Tabbar";
import { Analytics } from '../../compornents/Analytics';

export const Help = () => {
  const navigate = useNavigate();
  const back = () => {
    navigate(-1);
  };
  // メニュー選択
  const selectMenu = (menu) => {
    if (menu === 'help1') {
      navigate("/help1");
    } else if (menu === 'help2') {
      navigate("/help2");
    }
  };
  return (
    <div className="App">
      <div className="Address">
        <Analytics title="ヘルプ" />
        <div className="toolbar mb-3">
          <div className="toolbar_left div_btn" onClick={back} >
            <BsChevronLeft />
          </div>
          <div className="toolbar_center">
            ヘルプ
          </div>
          <div className="toolbar_right">
          </div>
        </div>
        <div className="m-4">
          <div className="account_list">
            <div className="account div_btn" onClick={() => selectMenu('help1')}>
              <div className="account_content2">
                よくある質問
              </div>
              <div className="account_arrow">
                <IoIosArrowForward />
              </div>
            </div>
            <div className="account div_btn" onClick={() => selectMenu('help2')}>
              <div className="account_content2">
                ご利用の流れ
              </div>
              <div className="account_arrow">
                <IoIosArrowForward />
              </div>
            </div>
          </div>
        </div>
        <Tabbar menu="account" />
      </div>
    </div>
  );



}
