/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Post } from '../compornents/Post';
import { useModal } from 'react-hooks-use-modal';
import { TailSpin } from 'react-loading-icons'
import { toast } from 'react-toastify';
import { Gtag, Linetag, FacebookPixel } from '../compornents/Analytics';

export const ItemMenu = () => {
  const navigate = useNavigate();
  const [items, setItems] = useState([]);

  // モーダル
  const [LoadingModal, openLoadingModal, closeLoadingModal] = useModal('root', {
    preventScroll: true //これはオプション。デフォルトはfalse
  });
  useEffect(() => {
    let unmounted = false;
    // 商品情報取得
    (async () => {
      openLoadingModal();
      const request_data = {};
      const response_data = await Post('post', '/order/item.php', request_data);
      if (response_data.result === 1) {
        const items = response_data.items;
        if (!unmounted) setItems(items);
        // console.log(response_data.items.length);
        // console.log(Number(response_data.items[0]['id']));
        // console.log(Number(response_data['is_step_view']));
        if (response_data.items.length===1){
          if (Number(response_data.items[0]['is_syokai']) === 1 && Number(response_data['is_step_view']) === 0) {
            navigate("/order_step/step0");
          }
        }
      } else {
        // エラー

      }
      closeLoadingModal();
    })();
    //クリーンアップ関数を返す
    return () => { unmounted = true; };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 商品選択
  async function selectItem(item) {
    Gtag('select_item', { item_id: item.id });
    Linetag('ViewItemDetail');
    FacebookPixel('AddToCart');
    openLoadingModal();
    // 見積もり取得
    const request_data = {item_id: item.id}
    const response_data = await Post('post', '/order/quote.php', request_data);
    // console.log(response_data);
    if (response_data.result === 1) {
      const quote = response_data.quote;
      navigate("/confirm", { state: { data:{item: item, quote: quote} } });
    } else {
      // エラー
      toast(response_data.error_message);
    }
    closeLoadingModal();
  };
  const ItemMenu = items.map((item) =>{
    const columnClass = Number(item.is_syokai) !== 1 && Number(item.id) !== -5  ? "col-6" : "col-12";
    return (<div className={`${columnClass} mb-2 div_btn`} onClick={() => selectItem(item)} key={item.id}>
      <img src={item.img} className="ItemImage" alt="Item" />
      <div className="">
        <div className="h6">
          {item.name}
        </div>
        <div className="text-gray-dark">
          <small>{item.intro}</small>
        </div>
        <div className="h6 text-right">
          ¥{Number(item.kin2).toLocaleString()}
        </div>
        {Number(item.id)===-5 && (
          <div className="mt-2 h6 text-danger">
            ※数量限定ですので、０円サービス終了前にお受け取りくださいませ
          </div>
        )}
      </div>
  </div>);
  }
  );
  return (
    <div>
      <div>
        <div className="">
          <div className="h5 mt-4 mb-4 text-center">
            <div className="home_title">メニューを<br />お選びください</div>
          </div>
          <div className="mb-4">
            <div className="row">
              {ItemMenu}
            </div>
          </div>
          {items.length > 1 && (
            <div className="text-right">
              <small>※別途Uber配達手数料が加算されます</small>
            </div>
          )}
          <div className="text-right">
            <small>※金額は全て税込です</small>
          </div>
        </div>
      </div>
      <LoadingModal>
        <div className="modal rounded text-center pb-4 pl-4 pr-4">
          <button onClick={closeLoadingModal} className="hide">閉じる</button>
          <div >
            <TailSpin stroke="#000" />
          </div>
          <div className="">
            <div className="">
              処理中です
            </div>
          </div>
        </div>
      </LoadingModal>
    </div>
  )
}
