import { useEffect } from "react";
import { PageControl, KIND } from "baseui/page-control";
import { Client as Styletron } from "styletron-engine-monolithic";
import { Provider as StyletronProvider } from "styletron-react";
import { LightTheme, BaseProvider } from "baseui";
// import {useStyletron} from 'baseui';
// import { FormControl } from "baseui/form-control";
import { useNavigate } from 'react-router-dom';
import { Analytics,Gtag } from '../../compornents/Analytics';
import React from 'react';

export const RegisterStep2 = () => {
  const navigate = useNavigate();
  const engine = new Styletron();

  const next = () => {
    navigate('/register/menu');
  }

  useEffect(() => {
    Gtag('view_register_step2');
  }, []);

  return (
    <StyletronProvider value={engine}>
      <BaseProvider theme={{ ...LightTheme, direction: "rtl" }}>
        <Analytics title="Step2" />
        <div className="Register">
          <div className="App-step1">
            <div className="App-h1 pt-4">{"STEP.2"}</div>
            <img src="/img/step2.png" alt="step2" className="App-img" />
            <div className="App-title-sm">Uber Eatsの配達パートナー<br />から専用バッグを受け取る</div>
            <div className="App-comment">ご自宅へUber Eatsの配達パートナーが<br />専用バッグをお届けに参ります</div>
            <div className="App-h3">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: '20px',
                }}
              >
                <PageControl
                  currentPage={2}
                  numPages={2}
                  kind={KIND.default}
                  aria-label="label"
                />
              </div>
              <button className="button-next-w" onClick={next}>{"次へ"}</button>
              <div><img src="../img/Direct_delivered with_Uber Eats_horiz_pill_green_en_1@2x.png" className="ud_img" alt="ud logo" /></div>
            </div>
          </div>
        </div>
      </BaseProvider>
    </StyletronProvider>
  );
}
