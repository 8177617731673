import { useState, useEffect, useCallback } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { BsChevronLeft } from "react-icons/bs";
import PayjpCheckout from 'react-payjp-checkout';
import { Tabbar } from "../compornents/Tabbar";
import { Post } from '../compornents/Post';
import { toast } from 'react-toastify';
import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps';
import { useModal } from 'react-hooks-use-modal';
import { TailSpin } from 'react-loading-icons';
import { Analytics,Gtag,FacebookPixel,Linetag } from '../compornents/Analytics';
import { IoMdCheckmark } from "react-icons/io";

export const Confirm = () => {
  const navigate = useNavigate();
  const [isAgree, setIsAgree] = useState(false);
  let location = useLocation();
  let [data, setData] = useState(location.state.data);
  let [syoukei, setSyoukei] = useState(0);
  let [goukei, setGoukei] = useState(0);
  const [customer, setCustomer] = useState([]);
  const position = { lat: 33.589173096297685, lng: 130.39530282258255 };
  const [isCenter, setCenter] = useState(position);
  const [isCreditError, setIsCreditError] = useState(false);
  // console.log(location);

  // モーダル
  const [LoadingModal, openLoadingModal, closeLoadingModal] = useModal('root', {
    preventScroll: true //これはオプション。デフォルトはfalse
  });
  const options = {
    disableDefaultUI: true,
    // デフォルトUI（衛星写真オプションなど）をキャンセルします。
    zoomControl: false,
    clickableIcons: false
  };
  // 合計金額計算
  const calcGoukei = useCallback(() => {
    let tmp_goukei = Number(data.item.kin2);
    if(data.coupon !== undefined){
      tmp_goukei = tmp_goukei - Number(data.coupon.kingaku);
      if (tmp_goukei < 0) {
        tmp_goukei = 0;
      }
    }
    setSyoukei(tmp_goukei);
    if (data.item.is_syokai !== '1' && data.item.id !== '-5') {
      tmp_goukei += Number(490);
    }
    setGoukei(tmp_goukei);
  }, [data]);
  // クーポンクリア
  const clearCoupon = () => {
    // data.coupon = undefined;
    data.coupon = undefined;
    setData(data);
    calcGoukei();
  }
  // 戻る
  const back = () => {
    navigate("/home");
    // navigate("/home", { state: { is_first_order: 1 } });
  };
  // 利用規約にどうしていください
  const requestAgree = () => {
    if(isAgree===false){
      toast('利用規約に同意してください');
    }else if(customer.card_type===null){
      toast('クレジットカードを登録してください');
      setIsCreditError(true);
    }
  }
  // クレジットカード登録
  async function createCredit() {
    // openLoadingModal();
    // console.log('createCredit');
    // // 見積もり取得
    let payjp_token = document.getElementsByName('create_order_payjp_token')[0].value;
    const request_data = { payjp_token: payjp_token };
    const response_data = await Post('post', '/customer/create_credit.php', request_data);
    // console.log(payjp_token);
    if (response_data.result === 1) {
      Gtag('create_credit_success');
      FacebookPixel('AddPaymentInfo');
      customer.card_type = response_data.card_type;
      customer.expYear = response_data.expYear;
      customer.expMonth = response_data.expMonth;
      customer.last4 = response_data.last4;
      setCustomer(response_data.customer);
    } else {
      // エラー
      toast(response_data.error_message);
      Gtag('create_credit_failed', {
        error_message: response_data.error_message,
      });
    }
    // closeLoadingModal();
  };
  // 商品作成
  async function createOrder() {
    openLoadingModal();
    // console.log('order');
    // 商品作成
    const request_data = { item_id: data.item.id, quote_id: data.quote.id,coupon_id: data.coupon?.id };
    const response_data = await Post('post', '/order/create.php', request_data);
    // console.log(response_data);
    if (response_data.result === 1) {
      Gtag('order_success', {
        order_id: response_data.order.id,
      });
      Linetag('Conversion');
      Gtag('conversion_event_purchase', {
        order_id: response_data.order.id,
      });
      FacebookPixel('Purchase', { value: + response_data.order.goukei, currency: 'JPY' });
      if (Number(data.item.id)===-5){
        // 初回注文
        navigate("/home", { state: { is_first_order: 1 } });
      } else {
        navigate("/home", { state: { is_first_order: 0 } });
      }
      Gtag(response_data.gatag_event, {
        order_id: response_data.order.id,
      });
    } else {
      // エラー
      toast(response_data.error_message);
      Gtag('order_failed', {
        error_message: response_data.error_message,
      });
    }
    closeLoadingModal();
  };
  //

  // function reactPayjpCheckoutOnCreated() {
  // const reactPayjpCheckoutOnCreated = () => {
  //   console.log('ok');
  // }
  // function reactPayjpCheckoutOnFailed(){
  //   console.log('fail');
  // }
  const fail = () => {
    // console.log('fail');
    toast('クレジットカード情報が正しくありません');
    Gtag('credit_failed');
  }

  var payjp_public_key = '';
  if (process.env.REACT_APP_IS_DEV === 'true') {
    payjp_public_key = "pk_test_88ed3508dd9a23c2c538aa38";
  } else {
    payjp_public_key = "pk_live_fa0603bf01da67561383016d";
  }
  const payjpCheckoutProps = {
    dataKey: payjp_public_key,
    dataText: '新規クレジットカード登録',
    dataPartial: 'true',
    dataSubmitText: '保存する',
    dataTokenName: 'create_order_payjp_token',
    dataPayjpThreeDSecure: 'true',
    dataPayjpThreeDSecureWorkflow: 'subwindow',
    dataPayjpExtraAttributePhone: 'true',
    onCreatedHandler: createCredit,
    onFailedHandler: fail,
  }

  useEffect(() => {
    Gtag('view_confirm');
    // 合計金額計算
    calcGoukei();
    // 注文情報取得
    async function getCustomer() {
      const request_data = {};
      const response_data = await Post('post', '/customer/index.php', request_data);
      // console.log(response_data);
      if (response_data.result === 1) {
        setCustomer(response_data.customer);
        setCenter({ lat: Number(response_data.customer.lat), lng: Number(response_data.customer.lon) });
      } else {
        // エラー

      }
    }
    getCustomer();
  }, [calcGoukei]);
  return (
    <div className="App">
      <div className="Confirm">
        <Analytics title="注文確認" />
        <div className="toolbar">
          <div className="toolbar_left">
            <div className="">
              <BsChevronLeft onClick={back} />
            </div>
          </div>
          <div className="toolbar_center">
            注文内容の確認
          </div>
          <div className="toolbar_right">
          </div>
        </div>
        <div className="">
          <div className="">
            <div className="">
              <div className="title">
              {data.item.id === '-5' ? (
                <>専用バッグお届け日時(予定)</>
              ) : (
                <>集荷日時(予定)</>
              )}
              </div>
              <div className="content">
                {data.quote?.in_business_hours === true ? (
                  <div>
                    <div>
                      およそ<strong className="text-primary">15分〜60分</strong>で
                      {data.item.id === '-5' ? (
                          <>お届け可能</>
                      ) : (
                        <>集荷可能</>
                      )}

                    </div>
                    <div className="th5">
                      受付時間：{data.quote?.business_hours_txt}
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="text-danger mb-2 h6">
                      申し訳ございません。現在は受付時間外のため注文できません。受付時間内に再度お試しください
                    </div>
                    <div className="h5">
                      受付時間：{data.quote?.business_hours_txt}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="">
            <div className="title">
              集荷先<span className="text-danger">（ピンの位置をご確認ください）</span>
            </div>
            <div className="content">
              <div className="">
                {customer.pref + customer.add1 + customer.add2 + customer.add3 + customer.room}
              </div>
              <div className="mb-3">
                <Link to="/address" state={{ data: data }}>編集</Link>
              </div>
              <div className="">
                <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
                  <div className="w-100 confirmMap">
                    <Map
                      center={isCenter}
                      zoom={18}
                      options={options}
                    >
                      <Marker position={isCenter} />
                    </Map>
                  </div>
                </APIProvider>
              </div>
            </div>
          </div>
          {goukei > 0 && (
            <>
              <div className="">
                <div className="title">
                  クレジットカード情報
                </div>
                <div className="content">
                  {customer === null ? (
                    <div>
                    </div>
                  ) : customer.card_type === null ? (
                    <>
                      <div className="mb-2">以下のカード会社に対応しております。</div>
                      <div className="mb-3">
                        <img src="./img/credit_brands.png" alt="step1" className="ItemImage" />
                      </div>
                      <div className="mb-2">
                        <PayjpCheckout {...payjpCheckoutProps} />
                        {isCreditError === true && (
                          <div className="text-danger mt-2">クレジットカードを登録してください</div>
                        )}
                      </div>
                    </>
                  ):(
                    <div className="mr-2 ml-2">
                        <div className="row">
                          <div className="col-2">
                          </div>
                          <div className="col-5">
                            カード番号
                          </div>
                          <div className="col-5">
                            有効期限
                          </div>
                        </div>
                        <hr/>
                        <div className="row">
                          <div className="col-2 pr-0">
                            {customer.card_type==='American Express' ? (
                              <img src="./img/ic/American Express.png" alt="CreditCard" className="ItemImage" />
                            ) : customer.card_type==='Diners Club' ? (
                              <img src="./img/ic/Diners Club.png" alt="CreditCard" className="ItemImage" />
                            ) : customer.card_type === 'Discover' ? (
                              <img src="./img/ic/Discover.png" alt="CreditCard" className="ItemImage" />
                            ) : customer.card_type === 'JCB' ? (
                              <img src="./img/ic/JCB.png" alt="CreditCard" className="ItemImage" />
                            ) : customer.card_type === 'MasterCard' ? (
                              <img src="./img/ic/MasterCard.png" alt="CreditCard" className="ItemImage" />
                            ) : customer.card_type === 'Visa' && (
                              <img src="./img/ic/Visa.png" alt="CreditCard" className="ItemImage" />
                            )}
                          </div>
                          <div className="col-5 pr-0">
                            **** **** **** {customer.last4}
                          </div>
                          <div className="col-3">
                            {customer.expMonth}/{customer.expYear}
                          </div>
                          <div className="col-2 text-right">
                            <IoMdCheckmark className="text-primary text-md"/>
                          </div>
                        </div>
                    </div>
                  )}

                </div>
              </div>

              <div className="">
                <div className="title">
                  クーポン
                </div>
                <div className="content">
                  {data.item.is_syokai === '1' ? (
                    <div>
                      新規入会特典はクーポンを使用できません
                    </div>
                  ) : (
                    <div>
                      {data.coupon !== undefined && (
                        <div>
                          <div className="row">
                            <div className="col-10">
                              <div>
                                {data.coupon.name}
                              </div>
                              <div>
                                {data.coupon.kingaku}円引き
                              </div>
                            </div>
                            <div className="col-2">
                              <div className="mt-2">
                              <Link
                                className="text-danger"
                                onClick={(event) => {
                                  event.preventDefault(); // ← 画面遷移を防ぐ
                                  clearCoupon(); // ← clearCoupon を実行
                                }}
                              >削除</Link>
                              </div>
                            </div>
                          </div>
                          <hr />
                        </div>
                      )}
                      <div>
                        <Link to="/coupon" state={{ data: data }}>クーポンを選択する</Link>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
          <div className="">
            <div className="title">
              ご注文内容の確認
            </div>
            <div className="content">
              <div className="row mb-2">
                <div className="col-8">
                  {data.item.name}
                </div>
                <div className="col-4 text-right">
                  ¥{Number(data.item.kin2).toLocaleString()}
                </div>
              </div>
              {data.coupon !== undefined && (
                <div className="row mb-2">
                  <div className="col-8 text-danger">
                    クーポン
                  </div>
                  <div className="col-4 text-right text-danger">
                    -¥{Number(data.coupon.kingaku).toLocaleString()}
                  </div>
                </div>
              )}
              <div className="mb-2 border-bottom"></div>
              <div className="row mb-2">
                <div className="col-8">
                  <strong>小計</strong>
                </div>
                <div className="col-4 text-right">
                  ¥{Number(syoukei).toLocaleString()}
                </div>
              </div>
              {data.item.id === '1' || data.item.id === '-5' ?
                <div>
                  <div className="row mb-2">
                    <div className="col-8">
                      <strong>Uber配達手数料</strong>
                    </div>
                    <div className="col-4 text-right">
                      ¥{Number(data.quote.fee).toLocaleString()}
                    </div>
                  </div>
                  <div className="row mb-2 text-danger h6">
                    <div className="col-8">
                      →初回無料！
                    </div>
                    <div className="col-4 text-right">
                      -¥{Number(data.quote.fee).toLocaleString()}
                    </div>
                  </div>
                </div>
                :
                <div className="row mb-2">
                  <div className="col-8">
                    Uber配達手数料（今なら往復分通常{Number(data.quote.fee).toLocaleString()}円→<span className="text-danger">490円</span>)
                  </div>
                  <div className="col-4 text-right">
                    ¥{Number(490).toLocaleString()}
                  </div>
                </div>
              }

              <div className="mb-2 border-bottom"></div>
              <div className="row">
                <div className="col-6 h5">
                  合計
                </div>
                <div className="col-6 text-right h4">
                  ¥{goukei.toLocaleString()}
                </div>
              </div>
            </div>
            <div className="explain mb-3">
              <div className="mt-1 mb-3">
                配達のため、個人情報を委託先の企業に提供する場合がございます。詳細は<a href="/rule" >利用規約</a>をご確認ください。
              </div>
              <div className="mb-3">
                <div className="">
                  <input type="checkbox" id="agree" onChange={() => { setIsAgree(!isAgree) }} />
                  <label htmlFor="agree">利用規約に同意する</label>
                </div>
              </div>
              <div className="mb-3">
                {isAgree === false || (customer.card_type==null && goukei>0) ? (
                  <div>
                    <button className="btn btn-oval btn-gray-dark form-control" onClick={requestAgree}>この内容で注文する</button>
                  </div>
                ) : (
                  <div>
                    <button className="btn btn-oval btn-yellow form-control" onClick={createOrder}>この内容で注文する</button>
                  </div>
                )}
              </div>
              <div className="mb-3">
                <button className="btn btn-oval btn-outline-secondary form-control" onClick={back}>メニューに戻る</button>
              </div>
            </div>
          </div>
        </div>
        <LoadingModal>
          <div className="modal rounded text-center pb-4 pl-4 pr-4">
            <button onClick={closeLoadingModal} className="hide">閉じる</button>
            <div >
              <TailSpin stroke="#000" />
            </div>
            <div className="">
              <div className="">
                処理中です
              </div>
            </div>
          </div>
        </LoadingModal>
        <Tabbar menu="home" />
      </div>

    </div>
  );
}
