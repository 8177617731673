import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { BsChevronLeft } from "react-icons/bs";
import { Tabbar } from "../compornents/Tabbar";
import { Post } from '../compornents/Post';
import { toast } from 'react-toastify';
import { useModal } from 'react-hooks-use-modal';
import { TailSpin } from 'react-loading-icons';
import { Analytics,Gtag } from '../compornents/Analytics';

export const Coupon = () => {
  const location = useLocation();
  // console.log(location);
  let data = undefined;
  if(location.state !== null && location.state.data !== undefined){
    data = location.state.data;
  }
  const navigate = useNavigate();
  const [coupons, setCoupons] = useState([]);
  const [coupon_no, setCouponNo] = useState("");
  // モーダル
  const [LoadingModal, openLoadingModal, closeLoadingModal] = useModal('root', {
    preventScroll: true //これはオプション。デフォルトはfalse
  });
  // クーポン作成
  async function createCoupon() {
    openLoadingModal();
    // console.log('update');
    // 見積もり取得
    const request_data = {
      'coupon_no': coupon_no
     };
    const response_data = await Post('post', '/customer/create_coupon.php', request_data);
    // console.log(response_data);
    if (response_data.result === 1) {
      Gtag('create_coupon_success');
      setCoupons(response_data.coupons);
    } else {
      // エラー
      toast(response_data.error_message);
      Gtag('create_coupon_failed');
    }
    closeLoadingModal();
  };
  // クーポン選択
  async function selectCoupon(coupon) {
    if(location.state !== null && location.state.data !== undefined){
        data.coupon = coupon;
        navigate("/confirm", { state: { data:data }});
    }else{
      navigate("/home");
    }
  }
  useEffect(() => {
    // 顧客情報取得
    async function getCoupons() {
      const request_data = {}
      // post
      const response_data = await Post('post', '/customer/coupons.php', request_data);
      // 結果
      // console.log(response_data);
      if (response_data.result === 1) {
        const coupons = response_data.coupons;
        setCoupons(coupons);
      } else {
        // エラー

      }
    }
    getCoupons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // 戻る
  const back = () => {
    navigate(-1);
  };

  const listCoupons = coupons.length > 0 ? coupons.map(coupon =>
    <div key={coupon.id}>
      <div className="CouponContainer">
        <div className="CouponInfo">
          <div className="m-0">{coupon.name}</div>
          <h5 className="m-0 w-100 text-center"><span className="text-md mr-2">{coupon.kingaku}</span>円引き</h5>
          <p className="m-0">有効期限: {coupon.kigen_day}まで</p>
        </div>
        {coupon.riyou === "0" ? (
          <div className="CouponUse" onClick={() => selectCoupon(coupon)}>
            使用する
          </div>
        ) : (
          <div className="CouponUsed">
            使用済み
          </div>
        )}
    </div>
    </div>
  ) : (<div>クーポンはありません</div>);
  return (
    <div className="App">
      <div className="Address">
        <Analytics title="クーポン" />
        <div className="toolbar mb-3">
          <div className="toolbar_left div_btn" onClick={back} >
            <BsChevronLeft />
          </div>
          <div className="toolbar_center">
            クーポン
          </div>
          <div className="toolbar_right">
          </div>
        </div>
        <div className="m-4">
          <div className="mb-4">
            クーポンコードを入力してください
          </div>
          <div className="mb-4">
            <input type="text" className="form-control" placeholder="クーポンコード" value={coupon_no} onChange={e => setCouponNo(e.target.value)}  />
          </div>
          <div className="mb-4">
            <button className="btn btn-oval btn-yellow form-control" onClick={() => createCoupon()}>追加する</button>
          </div>
          <hr />
          <div className="mb-4 mt-4">
            <div className="mb-4">
              マイクーポン
            </div>
            <div className="mb-4">
              {listCoupons}
            </div>
          </div>
        </div>
        <LoadingModal>
          <div className="modal rounded text-center pb-4 pl-4 pr-4">
            <button onClick={closeLoadingModal} className="hide">閉じる</button>
            <div >
              <TailSpin stroke="#000" />
            </div>
            <div className="">
              <div className="">
                処理中です
              </div>
            </div>
          </div>
        </LoadingModal>
        <Tabbar menu="account" />
      </div>
    </div>
  );



}
