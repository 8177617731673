import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
// import axios from 'axios';
import { Post } from '../../compornents/Post';
import { useModal } from 'react-hooks-use-modal';
import { TailSpin } from 'react-loading-icons'
import { Analytics,Gtag } from '../../compornents/Analytics';

export const RegisterPostcode = () => {
  const navigate = useNavigate();
  const [postcode, setPostcode] = useState("");
  const [isPostcodeError, setIsPostcodeError] = useState(false);

  // モーダル
  const [Modal, openModal, closeModal] = useModal('root', {
    preventScroll: true //これはオプション。デフォルトはfalse
  });


  async function checkPostcode() {
    // console.log(postcode);
    openModal();
    const request_data = {
      'key': 'yt~z7ESU6J~tLB7ppw(CLD8WddX9PNsr6DH',
      'postcode': postcode,
    }
    const response_data = await Post('public', '/auth/check_postcode.php', request_data);
    // 結果
    // console.log(response_data);
    // try {
    if (response_data.result === 1) {
      // 対象エリア内
      Gtag('within_service_area', { postcode: postcode });
      setIsPostcodeError(false);
      navigate('/register',{state:{postcode:postcode}});
      // window.location.replace('register');
    } else {
      // 対象エリア外
      Gtag('outside_service_area', { postcode: postcode });
      setIsPostcodeError(true);
    }
    // } catch (e){
    //   console.log(e);
    //   setIsPostcodeError(true);
    // }
    closeModal();
  }

  // エリア対象内になったら通知を受け取る
  function toArea() {
    // 対象エリア内
    Gtag('request_mail_within_service_area', { postcode: postcode });
    window.open('https://forms.gle/N2xDHLPpByedyF5p9', '_blank');
  };

  useEffect(() => {
    Gtag('view_register_postcode');
  }, []);

  return (
    <div>
      <Analytics title="郵便番号確認" />
      <div className="Register m-auto">
        <div className="App-tel">
          <div className="tel-title">{"自宅から\nクリーニングを注文"}</div>
          <div className="App-telno">
            <input type="text" inputMode="numeric" value={postcode} onChange={e => setPostcode(e.target.value)} placeholder="ご自宅の郵便番号を入力" className="form-control" onKeyDown={(event) => setIsPostcodeError(false)} />

            {isPostcodeError ?
                <div className="mt-2 mb-4 border rounded p-3 text-left">
                  <div className="mb-2 h6">
                    ご利用エリア外です
                  </div>
                  <div className="">
                    <button className="btn btn-sm btn-oval btn-black w-100" onClick={toArea}>利用可能になったら通知を受け取る &gt;</button>
                  </div>
                </div>
              : ''
            }
          </div>

          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '20px',
          }}>
            {postcode.length >= 7 ? <button className="button-next" onClick={checkPostcode}>{"次へ"}</button> : <button className="button-next-g">{"次へ"}</button>}
          </div>
        </div>
      </div>
      <Modal>
        <div className="modal rounded text-center pb-4 pl-4 pr-4">
          <button onClick={closeModal} className="hide">閉じる</button>
          <div >
            <TailSpin stroke="#000" />
          </div>
          <div className="">
            <div className="">
              処理中です
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
