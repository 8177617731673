/* eslint-disable no-unused-vars */
import { useState, useEffect, } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { BsChevronLeft } from "react-icons/bs";
import { Tabbar } from "../compornents/Tabbar";
import { Analytics } from '../compornents/Analytics';
import { useModal } from 'react-hooks-use-modal';
import { Post } from '../compornents/Post';
import { toast } from 'react-toastify';
import { TailSpin } from 'react-loading-icons';
import { Client as Styletron } from 'styletron-engine-monolithic';
import { Provider as StyletronProvider } from 'styletron-react';
import { LightTheme, BaseProvider } from 'baseui';

const engine = new Styletron();

export const Notification = () => {
  // notification_id取得
  let location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const notification_id = params.notification_id;
  const [notification, setNotification] = useState(location.state === null?[]:location.state.notification);
  // console.log(notification);
  // 戻る
  const back = () => {
    navigate("/notifications");
  };

  // モーダル
  const [LoadingModal, openLoadingModal, closeLoadingModal] = useModal('root', {
    preventScroll: true //これはオプション。デフォルトはfalse
  });

  useEffect(() => {
    // 注文情報取得
    async function readNotification() {
      openLoadingModal();
      const request_data = {
        'notification_id' : notification_id,
      }
      // post
      const response_data = await Post('post', '/notification/read.php', request_data);
      // 結果
      // console.log(response_data);
      if (response_data.result === 1) {
        // OK
        setNotification(response_data.notification);
      } else {
        // エラー
        toast(response_data.error_message);
      }
      closeLoadingModal();
    }
    readNotification();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification_id]);

  return (
    <StyletronProvider value={engine}>
      <BaseProvider theme={LightTheme}>
        <div className="App">
          <div className="Notification">
            <Analytics title="お知らせ内容" />
            <div className="toolbar">
              <div className="toolbar_left div_btn" onClick={back} >
                <BsChevronLeft />
              </div>
              <div className="toolbar_center">
                お知らせ内容
              </div>
              <div className="toolbar_right">
              </div>
            </div>
            <div className="mt-3 mb-5">
              <div className="order_body mb-3">
                <div className="order_header mb-2">
                  <div className="order_date">
                    {notification.od_day}
                  </div>
                </div>
                <div className="h4 mb-2">
                  {notification.title}
                </div>
                <div className="content mb-4 mt-4" style={{ whiteSpace: 'pre-line' }}>
                  {notification.matter}
                </div>
                {notification.img !== '' && notification.img !== undefined && (
                  <div className="content mb-4 mt-4">
                    <img src={notification.img} className="ItemImage" alt="Item" />
                  </div>
                )}
              </div>
            </div>
            <LoadingModal>
              <div className="modal rounded text-center pb-4 pl-4 pr-4">
                <button onClick={closeLoadingModal} className="hide">閉じる</button>
                <div >
                  <TailSpin stroke="#000" />
                </div>
                <div className="">
                  <div className="">
                    処理中です
                  </div>
                </div>
              </div>
            </LoadingModal>
            <Tabbar menu="notifications" />
          </div>
        </div>
      </BaseProvider>
    </StyletronProvider>
  );
}
