/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { RiMapPin2Line } from "react-icons/ri";
import { IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { Tabbar } from "../compornents/Tabbar";
import { Post } from '../compornents/Post';
import { useModal } from 'react-hooks-use-modal';
import { TailSpin } from 'react-loading-icons';
import { toast } from 'react-toastify';
import { Analytics } from '../compornents/Analytics';
import { Badge,PLACEMENT, SHAPE, COLOR } from "baseui/badge";
import { LightTheme, BaseProvider } from 'baseui';
import { Client as Styletron } from 'styletron-engine-monolithic';
import { Provider as StyletronProvider } from 'styletron-react';

const engine = new Styletron();

export const Notifications = () => {
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);

  // モーダル
  const [LoadingModal, openLoadingModal, closeLoadingModal] = useModal('root', {
    preventScroll: true //これはオプション。デフォルトはfalse
  });

  // お知らせ情報取得
  async function readNotification(notification) {
    openLoadingModal();
    const request_data = {
      'notification_id' : notification.id,
    }
    // post
    await Post('post', '/notification/read.php', request_data);
    closeLoadingModal();
  }

  // お知らせ選択
  async function selectNotification(notification) {
    if(notification.read_suu === '0') {
      // 既読
      await readNotification(notification);
    }
    // ページ遷移
    navigate("/notification/" + notification.id,{state:{notification:notification}});
  };

  useEffect(() => {
    // お知らせ情報取得
    async function getNotifications() {
      openLoadingModal();
      const request_data = {}
      // post
      const response_data = await Post('post', '/notification/index.php', request_data);
      // 結果
      // console.log(response_data);
      if (response_data.result === 1) {
        setNotifications(response_data.notifications);
      } else {
        // エラー
        toast(response_data.error_message);
      }
      closeLoadingModal();
    }
    getNotifications();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // console.log(notifications.length);
  const listNotifications = notifications.length > 0 ? notifications.map(notification =>
    <Badge content='new!'
      horizontalOffset="0"
      placement={PLACEMENT.topRightEdge}
      shape={SHAPE.pill}
      color={COLOR.negative}
      hidden={notification.read_suu !== '0'}
      key={notification.id}>
      <div className="order mb-3 div_btn" onClick={() => selectNotification(notification)}>
        <div className="order_content">
          <div className="order_header mb-2">
            <div className="order_date">
              {notification.od_day}
            </div>
          </div>
          <div className="order_item mb-4">
            {notification.title}
          </div>
        </div>
        <div className="order_arrow">
          <IoIosArrowForward />
        </div>
      </div>
    </Badge>
  ) : (<div>お知らせはありません</div>);
  return (
    <StyletronProvider value={engine}>
      <BaseProvider theme={LightTheme}>
        <div className="App">
          <div className="Orders">
            <Analytics title="お知らせ" />
            <div className="mt-3 mb-5">
              <div className="h3 mt-4 mb-4 text-left">
                お知らせ
              </div>
              <div className="">
                {listNotifications}
              </div>
            </div>
            <LoadingModal>
              <div className="modal rounded text-center pb-4 pl-4 pr-4">
                <button onClick={closeLoadingModal} className="hide">閉じる</button>
                <div >
                  <TailSpin stroke="#000" />
                </div>
                <div className="">
                  <div className="">
                    処理中です
                  </div>
                </div>
              </div>
            </LoadingModal>
            <Tabbar menu="notifications" />
          </div>
        </div>
      </BaseProvider>
    </StyletronProvider>
  );
}
