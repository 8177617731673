import { IoIosArrowForward,IoMdHelpCircleOutline } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { BiUser } from "react-icons/bi";
import { MdOpenInNew, MdOutlineCreditCard } from "react-icons/md";
import { CiShop, CiMail } from "react-icons/ci";
import { SlOptions } from "react-icons/sl";
import { Tabbar } from "../compornents/Tabbar";
import { Analytics } from '../compornents/Analytics';
import { RiCoupon3Line } from "react-icons/ri";
import { FaUserPlus } from "react-icons/fa";
import { IoDocumentTextOutline } from "react-icons/io5";

export const Account = () => {
  const navigate = useNavigate();
  // メニュー選択
  const selectMenu = (menu) => {
    if (menu === 'profile') {
      // navigate("/profile", { user ,setUser});
      navigate("/profile");
    } else if (menu === 'credit') {
      navigate("/credit");
    } else if (menu === 'cleaning') {
      navigate("/cleaning");
    } else if (menu === 'inquiry') {
      window.open('https://forms.gle/iedQt5jxNRuTnBvi7', '_blank');
    } else if (menu === 'coupon') {
      navigate("/coupon");
    } else if (menu === 'syokai') {
      navigate("/syokai");
    }else if (menu === 'questionnaire') {
      window.open('https://forms.gle/gSetafE2kWtJr9Yg7', '_blank');
    } else if (menu === 'help') {
      navigate("/help");
    } else if (menu === 'other') {
      navigate("/other");
    }
  };
  return (
    <div className="App">
      <div className="Account">
        <Analytics title="アカウント" />
        <div className="mt-3 mb-5">
          <div className="h3 ml-4 mt-4 mb-4 text-left">
            アカウント
          </div>
          <div className="account_list">
            <div className="account div_btn" onClick={() => selectMenu('profile')}>
              <div className="account_icon">
                <BiUser />
              </div>
              <div className="account_content">
                個人情報
              </div>
              <div className="account_arrow">
                <IoIosArrowForward />
              </div>
            </div>
            <div className="account div_btn" onClick={() => selectMenu('coupon')}>
              <div className="account_icon">
                <RiCoupon3Line />
              </div>
              <div className="account_content">
                クーポン
              </div>
              <div className="account_arrow">
                <IoIosArrowForward />
              </div>
            </div>
            <div className="account div_btn" onClick={() => selectMenu('syokai')}>
              <div className="account_icon">
                <FaUserPlus />
              </div>
              <div className="account_content">
                お友達紹介
              </div>
              <div className="account_arrow">
                <IoIosArrowForward />
              </div>
            </div>
            <div className="account div_btn" onClick={() => selectMenu('questionnaire')}>
              <div className="account_icon">
                <IoDocumentTextOutline />
              </div>
              <div className="account_content">
                アンケート
              </div>
              <div className="account_arrow">
                <MdOpenInNew />
              </div>
            </div>
            <div className="account div_btn" onClick={() => selectMenu('credit')}>
              <div className="account_icon">
                <MdOutlineCreditCard />
              </div>
              <div className="account_content">
                クレジットカード情報
              </div>
              <div className="account_arrow">
                <IoIosArrowForward />
              </div>
            </div>
            <div className="account div_btn" onClick={() => selectMenu('cleaning')}>
              <div className="account_icon">
                <CiShop />
              </div>
              <div className="account_content">
                提携クリーニング店
              </div>
              <div className="account_arrow">
                <IoIosArrowForward />
              </div>
            </div>
            <div className="account div_btn" onClick={() => selectMenu('help')}>
              <div className="account_icon">
                <IoMdHelpCircleOutline />
              </div>
              <div className="account_content">
                ヘルプ
              </div>
              <div className="account_arrow">
                <IoIosArrowForward />
              </div>
            </div>
            <div className="account div_btn" onClick={() => selectMenu('inquiry')}>
              <div className="account_icon">
                <CiMail />
              </div>
              <div className="account_content">
                お問い合せ
              </div>
              <div className="account_arrow">
                <MdOpenInNew />
              </div>
            </div>
            <div className="account div_btn" onClick={() => selectMenu('other')}>
              <div className="account_icon">
                <SlOptions />
              </div>
              <div className="account_content">
                その他
              </div>
              <div className="account_arrow">
                <IoIosArrowForward />
              </div>
            </div>
          </div>
        </div>
        <Tabbar menu="account" />
      </div>

    </div>
  );
}
