import { useNavigate } from "react-router-dom";
import { BsChevronLeft } from "react-icons/bs";
import { Tabbar } from "../../compornents/Tabbar";
import { Analytics } from '../../compornents/Analytics';

export const Help1 = () => {
  const navigate = useNavigate();
  const back = () => {
    navigate(-1);
  };
  return (
    <div className="App">
      <div className="Address">
        <Analytics title="よくある質問" />
        <div className="toolbar mb-3">
          <div className="toolbar_left div_btn" onClick={back} >
            <BsChevronLeft />
          </div>
          <div className="toolbar_center">
            よくある質問
          </div>
          <div className="toolbar_right">
          </div>
        </div>
        <div className="m-4">
          <div class="mb-5">
            <div class="h5">１.納期はいつですか？</div>
            <div>通常で中２営業日です。（工場稼働日に準じます）仕上がりましたら、ショートメールでお知らせいたします。※15時以降にお預けいただいたご依頼品は、翌営業日の扱いになります。予めご了承くださいませ。</div>
          </div>
          <div class="mb-5">
            <div class="h5">２. スーツ上下で２点、スリーピースは３点になるのでしょうか？</div>
            <div>はい。そうなります。</div>
          </div>
          <div class="mb-5">
            <div class="h5">３. たとえば、7点の場合は？</div>
            <div>依頼点数より大きい点数までご利用できるメニューをお選びください</div>
            <div>ただし、ご利用されない点数分につきましての返金や次回利用時への繰越はできませんのでご了承くださいませ</div>
          </div>
          <div class="mb-5">
            <div class="h5">４. パック上限数に満たない場合は割引されますか？</div>
            <div>されません。</div>
            <div>少ないコースを選択して追加決済を行うなどでご対応いただけると助かります。</div>
          </div>
          <div class="mb-5">
            <div class="h5">５. ベルトなどの付属品は1点になりますか？</div>
            <div>いいえ、点数カウント対象にはなりません。</div>
            <div>ただし申込時に、コメント欄に記載していただけると助かります。</div>
          </div>
          <div class="mb-5">
            <div class="h5">６. 破れなどがあったら？</div>
            <div>お預かり後、検品した際に、ご依頼品に異常があった際は、マイページからご連絡させて頂きます。</div>
          </div>
          <div class="mb-5">
            <div class="h5">７. シミがあったら？</div>
            <div>本サービスに染み抜き料金は含まれておりません。ただし、お客様のご要望がある場合は、別途料金がかかります。個別の料金につきましては、都度の事前見積もりになります。</div>
          </div>
          <div class="mb-5">
            <div class="h5">８. シルクやアンゴラ、カシミヤなどのデリケート素材も適用されますか？</div>
            <div>デリケート素材関連は、1点につき別途450円〜かかります。追加決済になります。</div>
          </div>
          <div class="mb-5">
            <div class="h5">９. 追加料金が発生する場合はありますか？</div>
            <div>あります。</div>
            <div>当社指定特殊品の対象となるアイテムは、通常のクリーニング工程での対応が難しく、よりデリケートな洗浄工程になるため追加料金をいただいております。</div>
            <div>【当社指定特殊品】</div>
            <div>アンゴラ・カシミア・シルク・麻・モヘア・アルパカ・ベロアダウン製品・エクセーヌ等を50%以上含む衣類。一部合成皮革・革・ビーズ・スパンコール・ラメ・レース・刺繍・羽毛付き・パーティードレス・その他デザイン性が高い商品など。</div>
            <div>また、担当クリーニング店によって対象依頼品が異なる場合もございますので、くわしくは担当クリーニング店さまにお問合せくださいませ。</div>
          </div>
          <div class="mb-5">
            <div class="h5">10. 受付できない衣類はありますか？？</div>
            <div>以下の衣類はお引き受けすることができません。</div>
            <ul>
              <li>毛皮類・皮革類・和服類・寝具・インテリア用品（絨毯、毛布シーツなど）など、特殊なクリーニングを施す衣類。</li>
              <li>下着、おむつ、ペット用品類など。</li>
              <li>バッグ・靴など洋服以外の衣類全般。</li>
              <li>洗濯絵表示が全て×印で、クリーニングできない衣類。</li>
              <li>洗濯絵表示がない衣類。</li>
              <li>ほつれ、穴あき、剥離、劣化などが見られる衣類。</li>
              <li>ボンディング加工の衣類。</li>
              <li>濡れている物・排泄物がついている物・焼きこげが付いている物・ススが付いている物など。</li>
              <li>合成皮革（ポリウレタン）・塩化ビニール素材の衣類</li>
              <li>バッグや靴など。</li>
            </ul>
            <div>以上の衣類をお預かりした場合、検品後にご返品させていただきます。尚、返品となったご依頼品分の返金は致しかねます。予めご了承下さい。スポットお渡し前にしっかりとご確認をお願いいたします。</div>
          </div>
          <div class="mb-5">
            <div class="h5">11. 装飾のあるボタンのキズがつかないか心配です。</div>
            <div>十分注意してクリーニングいたしますが、キズが付く可能性がないとは言えませんので、その点ご了承いただければお預かり可能です。もし、どうしてもご心配の場合は、事前に取り外しをお願いします。</div>
          </div>
          <div class="mb-5">
            <div class="h5">12. 衣類に革のベルトが縫い付けてありますが..</div>
            <div>皮革専門のクリーニングサービスではございませんので、劣化する可能性があります。</div>
            <div>またあらかじめ劣化が発生している場合はさらに酷くなる可能性もあります。</div>
          </div>
          <div class="mb-5">
            <div class="h5">13. 手づくりの衣類も受け付けてもらえますか？</div>
            <div>ご家庭で洗える素材で作られているのであれば、受付可能です。ただし、万一の事故が発生した場合は、補償はいたしかねますのでご了承ください。</div>
          </div>
          <div class="mb-5">
            <div class="h5">14. 加工メニューはありますか？</div>
            {/* <div>防虫加工、毛玉とり加工、汗抜き加工、撥水加工などがあります。1加工につき550円からお受けしております。</div> */}
            <div>今はございませんが、今後追加予定です。</div>
          </div>
          <div class="mb-5">
            <div class="h5">15. 専用バッグに入らない場合は？</div>
            <div>申し訳ございません。本サービスは、専用バッグに入る点数分までになります。</div>
          </div>
        </div>
        <Tabbar menu="account" />
      </div>
    </div>
  );



}
